import '../App.css';
import React, { Component, useEffect } from "react";

import Plausible from 'plausible-tracker'

import {Helmet} from "react-helmet";
import featureEvent from '../assets/dpcover.png';
import aabundle from '../assets/aabundle.png'
import zulu100K from '../assets/z100K.png';
import kcreator from '../assets/100k-creator.png';
import boltIcon from '../assets/boltIcon.svg';
import fdico from '../assets/fdicon.png';
import mcico from '../assets/mcicon.png';
import sjmface from '../assets/sjmface.png';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import storiesCard from "../assets/storiesCard.png";

var Buffer = require('buffer/').Buffer 



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDEs9eZ7uZr15k4snOwOBwemTnflooj8-Y",
  authDomain: "mellorlink.firebaseapp.com",
  projectId: "mellorlink",
  storageBucket: "mellorlink.appspot.com",
  messagingSenderId: "603346648587",
  appId: "1:603346648587:web:5fbd4a6f7afeae7e28110e",
  measurementId: "G-31CBLFG330"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const fdKey = process.env.REACT_APP_FD_API_KEY






// Initialize Firebase
// const app = initializeApp(firebaseConfig);

export default class Home extends Component {


  constructor(props) {
    super(props);

    this.state = {
      value: '',
      email: '',
      error: '',
      isValidEmail: true,
      submitted: false,
      isLoading: false,
      submittedSuccessfully: false, // Add this state variable
    }
      
  }


  componentDidMount(){
    // this.getSegments();
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  }

  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    const isValidEmail = this.validateEmail(email);

    this.setState({ isValidEmail, submitted: true, isLoading: true }, async () => {
      if (isValidEmail) {
        // console.log('Valid email submitted:', email);
        // Perform further actions for a valid email
        this.addToSegment(email);
        // Simulate loading for 2 seconds (replace with your API call)
        await new Promise((resolve) => setTimeout(resolve, 2000));
        
        // After loading, reset the loading state
        this.setState({ isLoading: false, submittedSuccessfully: true });
        
        // Redirect to the new URL
        window.location.href = '/stories-training';
        
      } else {
        // console.log('Invalid email submitted:', email);
        // You can also add logic here to highlight the input in red
        this.setState({ isLoading: false });
      }
    });
};

  async addToSegment(email) {
    // console.log("test: ", email);

    // if (this.state.isValidEmail){
    //   return console.log('error', email);
    // }

    const segId = ['66b599783cabe1b890e3ce14'];
    const url = `https://api.flodesk.com/v1/subscribers/${email}/segments`;
  
    const body = {
      segment_ids: segId
    };
    
    const username = process.env.REACT_APP_FD_API_KEY; // Replace with your Flodesk API key
    const password = ' ';
  
    const auth = 'Basic ' + Buffer.from(username + ':' + password).toString('base64');    
  
    const options = {
      method: 'POST',
      headers: {
        'Authorization': auth,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log('Added to segment:', data);

    } catch (error) {
      console.error('Error adding to segment:', error);
    }
  }
  


  async getSegments(){
      // console.log('start segment')
      try {
        const segUrl = 'https://api.flodesk.com/v1/segments';
        const username = process.env.REACT_APP_FD_API_KEY; // Replace with your Flodesk API key
        const password = ' ';
  
        const auth = 'Basic ' + Buffer.from(username + ':' + password).toString('base64');
        // console.log(auth)
        const response = await fetch(segUrl, {
          method: 'GET',
          headers: {
            'Authorization': auth,
            'Content-Type': 'application/json',
          },
        });
        
        if (response.ok) {
          const data = await response.json();
          // this.setState({ segments: data.data, loading: false });
          // console.log(data);
        } else {
          // console.error('Error fetching segments:', response.statusText);
          // this.setState({ loading: false });
        }
      } catch (error) {
        // console.error('Error fetching segments:', error);
        // this.setState({ loading: false });
      }
    
  }


  featBtn(dir, tracker){

    // console.log('I clicked: ', dir + ' ' + tracker);
    this.logConv(tracker);

    window.open(dir, "_blank");
  }

  regularBtn(dir, tracker){

    // console.log('I clicked: ', dir + ' ' + tracker);
    this.logConv(tracker);
    window.open(dir, "_blank");
  }



  logConv(pos){
    logEvent(analytics, pos);
    // console.log('fired event: ', pos);
    return 
  }

  

  render() {
    const { email, isValidEmail, isLoading, submittedSuccessfully } = this.state;
    const inputStyle = {
      border: isValidEmail ? '1px solid #4a4a4a' : '1px solid #FF4343',
    };

    return (

      <div>
         <Helmet>
                
                
                <meta charSet="utf-8" />
                <title>Steven Mellor | Automated Creator | Helping you monetize and automate your Personal Brands</title>
                <link rel="canonical" href="http://thestevenmellor.com/" />
      
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://thestevenmellor.com/" />
                <meta property="og:title" content="Steven Mellor / The Automated Creator" />
                <meta property="og:description" content="Helping you monetize and automate your Personal Brands" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/mellorlink.appspot.com/o/acThumb1.png?alt=media&token=40d4a7eb-59de-4fa0-a261-f7e0c86df374" />
      
               
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://thestevenmellor.com/" />
                <meta property="twitter:title" content="Steven Mellor / The Automated Creator" />
                <meta property="twitter:description" content="Helping you monetize and automate your Personal Brands" />
                <meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/mellorlink.appspot.com/o/acThumb1.png?alt=media&token=40d4a7eb-59de-4fa0-a261-f7e0c86df374" />
      
            
                     
            </Helmet>
      


        <div className="mainCont">
         <div className="heroMessage gradient-text">Solo creator building an automated digital empire.</div>
         <div className="heroSubmessage">building in public — documenting everything</div>
         <div className='spacer30'></div>
          {/* <div className='featureCard'>
                  <div className="floatingPrice">Free Training</div>
                  <div className="spacer30"></div>
                  <img className='eventCard' src={storiesCard} alt="featured" width="100"/>
          
                  <div className='buttonTitle centerText'> 3 Story Automations that print leads and drive sales</div>
                  <div className='buttonDesc'>Turn Simple Instagram Stories into Consistent Revenue Streams</div>
                  {submittedSuccessfully ? (
                    <div className="thankYouHomeMessage">You're In! Sending you an email now</div>
                  ) : (
                  <form 
                  onSubmit={this.handleSubmit}
                  className='waitlistForm'>
                      <input
                            type="email"
                            value={email}
                            className='inputForm'
                            placeholder="Enter your email"
                            onChange={this.handleEmailChange}
                            style={inputStyle} 
                      />
                      <div 
                      className="featureBtn" 
                      type="submit" 
                      onClick={this.handleSubmit}>
                        {isLoading ? <div className="loader"></div> : 'Watch the Free Training'}
                      </div>
                  </form>
                  )}
                  {!isValidEmail && <div className="errorMessage">Something looks off with your email.</div>}
            </div> */}
            {/* <div className="spacer30"></div> */}
      <div className='featureCard ' onClick={()=>this.featBtn('https://stevenmellor.com/automatedcreator-2', 'links_buyAC')}>  
          
          <img className='eventCard' src="https://firebasestorage.googleapis.com/v0/b/mellorlink.appspot.com/o/acThumb1.png?alt=media&token=40d4a7eb-59de-4fa0-a261-f7e0c86df374" alt="featured" width="100"/>
          <div className='buttonTitle'>Automated Creator Course</div>
          <div className='buttonContext2'>Discover How To Automate Instagram to 6-Figures</div>
          
          <div className="featureBtn"><img className="badgeLogo" src={boltIcon} />Unlock Instant Access -&gt;</div>
      </div>
      {/* <div className='spacer30'></div> */}

        {/* <div className='buttonRow' onClick={()=>this.regularBtn('https://www.skool.com/hybrid-creator/about', 'Hybrid Creator Community')}>
            <div className="buttonIcon"></div>
            <div className='buttonTitle2'>Join the Community</div>
            <div className="buttonContext">Courses + Community + Q&A</div>
            <div className="freetag2">Skool -&gt;</div>
          </div> */}

      <div className="sectionDiv"></div>

      {/* <div className='buttonRow' onClick={()=>this.regularBtn('https://stevenmellor.com/automatedcreator', 'Automated Creator')}>
            <div className="buttonIcon"></div>  
              <div className='buttonTitle2 margin-left-70'>Automated Creator</div>
              <div className="buttonContext margin-left-70">Discover How To Automate Instagram to 6-Figure</div>
              <div className="freetag2">Get -&gt;</div>
          </div> */}

      <div className='buttonRow' onClick={()=>this.regularBtn('https://thestevenmellor.thrivecart.com/stories-to-sales-system-only/?coupon=SAVE100', 'Stories to Sales Course')}>
            <div className="buttonIcon"></div>  
              <div className='buttonTitle2 margin-left-70'>Stories to Sales Course</div>
              <div className="buttonContext margin-left-70">Access the complete Instagram Stories to Sales system</div>
              <div className="freetag2">Get -&gt;</div>
          </div>

      {/* <div className="sectionDiv">Work with me</div>

          <div className='buttonRow' onClick={()=>this.regularBtn('https://thestevenmellor.notion.site/Automated-Creator-Service-ee5c3e30854a4fb5b684acdac6ee02a5?pvs=4', '1:1 Coaching')}>
       
              <div className='buttonTitle2'>Let's work together</div>
              <div className="buttonContext">Get your systems dialed in</div>
              <div className="freetag2">Apply -&gt;</div>
          </div> */}
    
          <div className="sectionDiv"></div>
          <div className='buttonRow' onClick={()=>this.regularBtn('https://thestevenmellor.gumroad.com/l/storiestosales', 'Stories to Sales')}>
          {/* <div className="buttonIcon"></div> */}
            <div className='buttonTitle2'>Free Story Templates</div>
            <div className="buttonContext">Get 3 High Quality Instagram Story Templates that work</div>
            <div className="freetag2">Free -&gt;</div>
          </div>

        <div className='buttonRow' onClick={()=>this.regularBtn('https://thestevenmellor.gumroad.com/l/27-digital-product-ideas', '27 Digital Products')}>
            <div className='buttonTitle2'>27 Digital Product Ideas</div>
            <div className="buttonContext">The digital product starter kit</div>
            <div className="freetag2">Free -&gt;</div>
          </div>

          <div className='buttonRow' onClick={()=>this.regularBtn('https://thestevenmellor.gumroad.com/l/4-manychat-automations', '4 ManyChat Templates')}>
          {/* <div className="buttonIcon"></div> */}
            <div className='buttonTitle2'>Free ManyChat Templates </div>
            <div className="buttonContext">ManyChat Automations for Instagram</div>
            <div className="freetag2">Free -&gt;</div>
          </div>


             

 

          

          

          {/* <div className='buttonRow' onClick={()=>this.regularBtn('https://buy.stripe.com/cN2cOTfEQ8UM0OAcMV', '1:1 Coaching')}>
              <div className='buttonTitle2'>Apply to work with me <span className="halo">&nbsp;-&gt;</span></div>
          </div> */}
          
          
          <div className="spacer50"></div>
          


          

          

        
          </div>
        </div>
    );
    }
}





