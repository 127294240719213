import '../App.css';
import './Caption.css';
import React, { Component, createRef } from "react";
import { toPng } from 'html-to-image';
import { saveAs } from 'file-saver';
import { domToPng } from 'modern-screenshot'
import { Helmet } from "react-helmet";
import { Tweet } from 'react-tweet'

import xlogo from '../assets/twitter-x.svg';
import skLogo from '../assets/skoolIcon.png';
import ttLogo from '../assets/tiktokLogo.svg';
import tokTail from '../assets/tokcommenttail.svg';
import downloadIcon from '../assets/dlIcon.png';
import defaultAvi from '../assets/defaultAvatar.png';
import srLogo from '../assets/snapReplyLogo.png';
import srLogo2 from '../assets/snapreply.png';
import threadCheck from '../assets/threadCheck.svg';

import sendWhite from '../assets/sendWhite.svg';
import sendBlack from '../assets/sendBlack.svg';

import repostIconWhite from '../assets/repostIconWhite.svg';
import repostIconBlack from '../assets/repostIconBlack.svg';

import commentIconWhite from '../assets/commentIconWhite.svg';
import commentIconBlack from '../assets/commentIconBlack.svg';

import likeIconWhite from '../assets/likeIconWhite.svg';
import likeIconBlack from '../assets/likeIconBlack.svg';

var Buffer = require('buffer/').Buffer 

const cardClasses = [
  'whiteCard',
  'blackCard',
  'neonCard',
  'limeCard',
  'fireCard',
  'pinkCard',
  'redCard',
  'orangeCard',
  'greenCard',
  'blueCard',
  'purpleCard',
];

const fdKey = process.env.REACT_APP_FD_API_KEY

export default class Caption extends Component {
  constructor(props) {
    super(props);
    this.ref1 = createRef();
    this.ref2 = createRef();
    this.ref3 = createRef();
    this.ref4 = createRef();
    this.ref5 = createRef();
    this.canvasRef = createRef();
    this.canvasRef2 = createRef();
    this.canvasRef3 = createRef();
    this.canvasRef4 = createRef();
    this.canvasRef5 = createRef();
    this.state = {
      questionText: 'What format are we talking here on the deep dive video my man?',
      inputQuestionText: 'Type out the comment reply you want to save', // Separate state for input field
      username: 'thestevenmellor',
      avatarUrl: defaultAvi,
      questionCharCount: 55, // Initial character count
      cardClassIndex: 0, // Initial card class index
      version: 'threadPost',
      questionTitle: 'Ask me a question',
      questionResponse: 'What question do you want to answer?',
      tokReply: 'thestevenmellor',
      tokQuestion: 'What question do you want to answer?',
      threadContent: "Your boss will never pay you enough to live next to him.",
      threadLikes: '244',
      threadComments: '24',
      threadReposts: '2',
      threadSends: '1',
      value: '',
      email: '',
      error: '',
      isValidEmail: true,
      submitted: false,
      isLoading: false,
      submittedSuccessfully: false, // Add this state variable
      showThreadMetrics: true, // New state variable
      showThreadCheckmark: true, // New state for thread checkmark
      isWhiteCard: false, // New state for card color
      currentSendIcon: sendWhite, // New state for send icon
      currentLikeIcon: likeIconWhite,
      currentCommentIcon: commentIconWhite,
      currentRepostIcon: repostIconWhite,
      tweetText: '',
      error: null,
      tweetUrl: '',
      tweetId: '',
      submittedSuccessfully: false,
      isLoading: false,
    };

    this.handleTweetUrlChange = this.handleTweetUrlChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  componentDidMount() {
    setTimeout(this.scrapeTweet, 2000);
  }

  scrapeTweet = () => {
    // Find the tweet text within the embedded HTML
    const tweetDiv = document.querySelector('#tweet-container');
    if (tweetDiv) {
      const tweetText = tweetDiv.querySelector('p')?.innerText;
      this.setState({ tweetText: tweetText || 'Could not find tweet text' });
    }
  };

  getInputClass() {

    return this.state.tweetUrl ? "inputValid" : "inputInvalid";
  }

  handleTweetUrlChange(event) {
    console.log('handle change' , event.target.value);
    this.setState({ tweetUrl: event.target.value });
    const tweetUrl = event.target.value;

    // Extract the tweet ID using a regular expression
    const tweetIdMatch = tweetUrl.match(/status\/(\d+)/);
    const tweetId = tweetIdMatch ? tweetIdMatch[1] : null;
    console.log('tweet url is: ', tweetId);

    this.setState({ tweetId: tweetId });
  }

  handleSubmit2(event) {
    console.log('handle tweet', this.state.tweetUrl);
    // event.preventDefault();

  

    // Extract the tweet ID using a regular expression
    // const tweetIdMatch = tweetUrl.match(/status\/(\d+)/);
    // const tweetId = tweetIdMatch ? tweetIdMatch[1] : null;
    // console.log('tweet url is: ', tweetId);
 
  }



  toggleThreadMetrics = () => {
    this.setState((prevState) => ({
      showThreadMetrics: !prevState.showThreadMetrics
    }));
  }

  toggleThreadCheckmark = () => {
    this.setState((prevState) => ({
      showThreadCheckmark: !prevState.showThreadCheckmark
    }));
  }

 
  
  handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ avatarUrl: reader.result }, this.drawAvatar);
      };
      reader.readAsDataURL(file);
    }
  };

  drawAvatar = () => {
    const canvas = this.canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const { width, height } = canvas;
        const size = Math.min(img.width, img.height);
        const sx = (img.width - size) / 2;
        const sy = (img.height - size) / 2;
        canvas.width = width * 4; // Increasing the resolution
        canvas.height = height * 4; // Increasing the resolution
        ctx.scale(4, 4); // Scaling to 4x for higher quality
        ctx.clearRect(0, 0, width, height);
        ctx.beginPath();
        ctx.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2, true);
        ctx.closePath();
        ctx.clip();
        ctx.drawImage(img, sx, sy, size, size, 0, 0, width, height);
        this.setState({ avatarUrl: canvas.toDataURL() });
      };
      img.src = this.state.avatarUrl;
    }
  };

  // Function to detect mobile device
  isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  // Function to get the correct reference based on the version state
  getReference = () => {
    switch (this.state.version) {
      case 'comment':
        return this.ref1.current;
      case 'question':
        return this.ref2.current;
      case 'tiktok':
        return this.ref3.current;
      case 'threadPost':
        return this.ref4.current;
      case 'xPost':
        return this.ref5.current;
      case 'skoolPost':
        return this.ref6.current;
      default:
        return null;
    }
  }



  renderImageMobile = () => {
    const ref = this.getReference();
    const isTikTok = ref === this.ref3.current; // Check if the reference is for TikTok
  
    return new Promise((resolve, reject) => {
      if (ref) {
        const width = ref.offsetWidth;
        const height = isTikTok ? ref.offsetHeight + 30 : ref.offsetHeight; // Add 30px to the height only for TikTok
  
        toPng(ref, {
          pixelRatio: 2,
          quality: 1,
          width: width,
          height: height,
          style: isTikTok ? { paddingBottom: '30px' } : {}, // Ensure the padding is accounted for in the capture only for TikTok
        })
        .then((dataUrl) => {
          // Render the image again to ensure the profile avatar is loaded
          toPng(ref, {
            pixelRatio: 2,
            quality: 1,
            width: width,
            height: height,
            style: isTikTok ? { paddingBottom: '30px' } : {}, // Ensure the padding is accounted for in the capture only for TikTok
          })
          .then((secondDataUrl) => {
            resolve(secondDataUrl);
          })
          .catch((err) => {
            reject(err);
          });
        })
        .catch((err) => {
          reject(err);
        });
      } else {
        reject(new Error('Reference is not available'));
      }
    });
  };
  
  
  saveImageMobile = (dataUrl) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const scale = 2;

      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      ctx.scale(scale, scale);
      ctx.drawImage(img, 0, 0, img.width, img.height);

      canvas.toBlob((blob) => {
        saveAs(blob, 'image.png');
      }, 'image/png', 1);
    };
    img.src = dataUrl;
  };

  downloadImageMobile = async () => {
    try {
      await this.renderImageMobile();
      const dataUrl = await this.renderImageMobile();
      this.saveImageMobile(dataUrl);
    } catch (err) {
      console.error('Error generating image:', err);
    }
  };

  // Desktop Functions
  renderImageDesktop = async () => {
    const ref = this.getReference();
    if (ref) {
      const isTikTok = ref === this.ref3.current; // Check if the reference is for TikTok
      try {
        const dataUrl = await domToPng(ref, {
          quality: 1,
          scale: 5,
          height: isTikTok ? ref.clientHeight + 30 : ref.clientHeight // Add 30px to the height only for TikTok
        });
        console.log('render desktop line 171');
        return dataUrl;
      } catch (err) {
        throw new Error('Error rendering image: ' + err);
      }
    } else {
      throw new Error('Reference is not available');
    }
  };

  saveImageDesktop = (dataUrl) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const scale = 1;

      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      ctx.scale(scale, scale);
      ctx.drawImage(img, 0, 0, img.width, img.height);

      canvas.toBlob((blob) => {
        saveAs(blob, 'image.png');
      }, 'image/png', 1);
    };
    img.src = dataUrl;
  };

  downloadImageDesktop = async () => {
    try {
      await this.renderImageDesktop();
      const dataUrl = await this.renderImageDesktop();
      this.saveImageDesktop(dataUrl);
    } catch (err) {
      console.error('Error generating image:', err);
    }
  };

  // Conditional Function Call
  downloadImage = async () => {
    if (this.isMobileDevice()) {
      await this.downloadImageMobile();
    } else {
      await this.downloadImageDesktop();
    }
  };

  handleInputChange = (event) => {
    const questionText = event.target.value;
    this.setState({ 
      questionText: questionText || 'What format are we talking here on the deep dive video my man?',
      inputQuestionText: questionText,
      questionCharCount: questionText.length,
    });
  };

 

  handleQuestionTitle = (event) => {
    const questionTitle = event.target.value;
    if (questionTitle.length <= 65) {
      this.setState({ questionTitle });
    }
  };

  handleQuestionResponse = (event) => {
    const questionResponse = event.target.value;
    this.setState({ 
      questionResponse: questionResponse || '',
    });
  };

  handleTokReply = (event) => {
    const tokReply = event.target.value;
    if (tokReply.length <= 65) {
      this.setState({ tokReply });
    }
  };

  handleTokQuestion = (event) => {
    const tokQuestion = event.target.value;
    this.setState({ 
      tokQuestion: tokQuestion || '',
    });
  };

  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value });
  };

  truncateUsername = (username) => {
    return username.length > 16 ? username.slice(0, 16) + '...' : username;
  };

  toggleCardColor2 = () => {
    this.setState(prevState => ({
      cardClassIndex: (prevState.cardClassIndex + 1) % cardClasses.length,
    }));
  };


  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  }
  
  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
  handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    const isValidEmail = this.validateEmail(email);
  
    this.setState({ isValidEmail, isLoading: true }, async () => {
      if (isValidEmail) {
        await this.addToSegment(email);
        this.setState({ isLoading: false, submittedSuccessfully: true });
      } else {
        this.setState({ isLoading: false });
      }
    });
  }
  
  addToSegment = async (email) => {
    const segId = ['66b2f5852f72c13cb39ab17d'];
    const url = `https://api.flodesk.com/v1/subscribers/${email}/segments`;
  
    const body = {
      segment_ids: segId
    };
  
    
    const apiKey = process.env.REACT_APP_FD_API_KEY; // Replace with your Flodesk API key
    const auth = 'Basic ' + Buffer.from(apiKey + ':').toString('base64');
  
    const options = {
      method: 'POST',
      headers: {
        'Authorization': auth,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };
  
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log('Added to segment:', data);
    } catch (error) {
      console.error('Error adding to segment:', error);
    }
  }
  
  getSegments = async () => {
    const segUrl = 'https://api.flodesk.com/v1/segments';
    const apiKey = process.env.REACT_APP_FD_API_KEY; // Replace with your Flodesk API key
    const auth = 'Basic ' + Buffer.from(apiKey + ':').toString('base64');
  
    try {
      const response = await fetch(segUrl, {
        method: 'GET',
        headers: {
          'Authorization': auth,
          'Content-Type': 'application/json'
        }
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(data);
      } else {
        console.error('Error fetching segments:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching segments:', error);
    }
  }
  

  handleThreadContentChange = (e) => {
    this.setState({ threadContent: e.target.value });
  };

  handleThreadLikesChange = (e) => {
    this.setState({ threadLikes: e.target.value });
  };

  handleThreadCommentsChange = (e) => {
    this.setState({ threadComments: e.target.value });
  };

  handleThreadRepostsChange = (e) => {
    this.setState({ threadReposts: e.target.value });
  };

  handleThreadSendsChange = (e) => {
    this.setState({ threadSends: e.target.value });
  };

  handleUsernameChange = (e) => {
    this.setState({ username: e.target.value });
  };

  toggleCardColor = () => {
    this.setState((prevState) => ({
      isWhiteCard: !prevState.isWhiteCard,
      currentSendIcon: prevState.isWhiteCard ? sendWhite : sendBlack,
      currentLikeIcon: prevState.isWhiteCard ? likeIconWhite : likeIconBlack,
      currentCommentIcon: prevState.isWhiteCard ? commentIconWhite : commentIconBlack,
      currentRepostIcon: prevState.isWhiteCard ? repostIconWhite : repostIconBlack,
    }));
  }

  getInputClass = () => {
    return this.state.isValidEmail ? 'inputForm2' : 'inputForm2 inputError';
  }


  adjustTextareaHeight = (e) => {
    const textarea = e.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  decreaseThreadComments = () => {
    this.setState((prevState) => ({
      threadComments: Math.max(Number(prevState.threadComments) - 1, 0), // Ensure the value doesn't go below 0
    }));
  };

  increaseThreadComments = () => {
    this.setState((prevState) => ({
      threadComments: Number(prevState.threadComments) + 1, // Ensure the value is a number before adding
    }));
  };

  handleInputChange2 = (event) => {
    const value = event.target.value;
    if (value === '' || /^[0-9\b]+$/.test(value)) { // Allow only numbers or empty string
      this.setState({ threadComments: value === '' ? '' : Number(value) });
    }
  };

  render() {
    const truncatedUsername = this.truncateUsername(this.state.username);
    const { avatarUrl, questionCharCount, cardClassIndex, version, questionText, inputQuestionText, questionResponse, questionTitle, tokQuestion, tokReply } = this.state;
    const cardClass = cardClasses[cardClassIndex];
    
    // const { email, isValidEmail, isLoading, submittedSuccessfully } = this.state;
    // const inputStyle = {
    //   border: isValidEmail ? '1px solid #4a4a4a' : '1px solid #FF4343',
    // };
        const { tweetData, error } = this.state;

      

    //threads
    const{ threadLikes, threadComments, threadSends, threadReposts, threadVerified, threadContent } = this.state;

    const { tweetUrl, submittedSuccessfully, isLoading } = this.state;
    const inputStyle = {}; // Add your input styling here


    return (
      <div>
        <Helmet>
          {/* <meta charSet="utf-8" /> */}
          <title>SnapReply — Beautiful Comment Images in seconds</title>
          {/* <link rel="canonical" href="http://thestevenmellor.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://thestevenmellor.com/" />
          <meta property="og:title" content="Steven Mellor / The Automated Creator" />
          <meta property="og:description" content="Helping you monetize and automate your Personal Brands" />
          <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/mellorlink.appspot.com/o/acThumb1.png?alt=media&token=40d4a7eb-59de-4fa0-a261-f7e0c86df374" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://thestevenmellor.com/" />
          <meta property="twitter:title" content="Steven Mellor / The Automated Creator" />
          <meta property="twitter:description" content="Helping you monetize and automate your Personal Brands" />
          <meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/mellorlink.appspot.com/o/acThumb1.png?alt=media&token=40d4a7eb-59de-4fa0-a261-f7e0c86df374" /> */}
        </Helmet>
  
        <div className="mainCont4">
            <div className="snapBar">
              <div className='snapLogo'>
                <img src={srLogo2} className='desktopLogo' />
                <img src={srLogo} className='mobileLogo' />
              </div>
              {/* {submittedSuccessfully ? (
                  <div className="thankYouMessage">You're In! Sending you an email now</div>
                ) : (
                  <form onSubmit={this.handleSubmit} className="waitlistForm2">
                    <div className="inputContainer">
                      <input
                        type="email"
                        value={email}
                        className={this.getInputClass()}
                        placeholder="Subscribe for free growth tips"
                        onChange={this.handleEmailChange}
                        style={inputStyle}
                      />
                      <button className="featureBtn2" type="submit">
                        {isLoading ? <div className="loader"></div> : 'Subscribe'}
                      </button>
                    </div>
                  </form>
                )} */}
                {submittedSuccessfully ? (
                    <div className="thankYouMessage">Tweet ID: {this.state.tweetId}</div>
                  ) : (
                    <form onSubmit={this.handleSubmit2} className="waitlistForm2">
                      <div className="inputContainer">
                        <input
                          type="text"
                          value={tweetUrl}
                          className={this.getInputClass()}
                          placeholder="Paste your tweet URL here"
                          onChange={this.handleTweetUrlChange}
                          style={inputStyle}
                        />
                        {/* <button className="featureBtn2" type="submit">
                          {isLoading ? <div className="loader"></div> : 'Extract Tweet ID'}
                        </button> */}
                      </div>
                    </form>
                  )}

              {/* <div className="pageTitle">SnapReply</div> */}
            </div>
          <div className="container4">
            
  
            <div className="tabContainer">
              <div 
                className={`tab ${version === 'comment' ? 'active' : ''}`} 
                onClick={() => this.setState({ version: 'comment' })}
              >
                <span className="chipTitle"><span className="sansBold orange">@</span>IG Comment</span>
              </div>
              <div 
                className={`tab ${version === 'question' ? 'active' : ''}`} 
                onClick={() => this.setState({ version: 'question' })}
              >
                <span className="chipTitle"><span className="sansBold purple">@</span>IG Question</span>
              </div>
              <div 
                className={`tab ${version === 'tiktok' ? 'active' : ''}`} 
                onClick={() => this.setState({ version: 'tiktok' })}
              >
                <span className="chipTitle"><span className="sansBold red"><img src={ttLogo} width={16} /></span>TikTok Comment</span>
              </div>
              <div 
                className={`tab ${version === 'threadPost' ? 'active' : ''}`} 
                onClick={() => this.setState({ version: 'threadPost' })}
              >
                <span className="chipTitle"><span className="sansBold">@</span>Thread Post</span>
              </div>
              
              <div 
                className={`tab ${version === 'xPost' ? 'active' : ''}`} 
                onClick={() => this.setState({ version: 'xPost' })}
              >
                <span className="chipTitle"><span className="sansBold"><img src={xlogo} /></span>Tweet</span>
              </div>

              <div 
                className={`tab ${version === 'skoolComment' ? 'active' : ''} grayOut`} 
                // onClick={() => this.setState({ version: 'skoolComment' })}
              >
                <span className="chipTitle"><span className="sansBold green"><img src={skLogo} width={16}/></span>Skool Comment</span>
              </div>
              
            </div>
  
            {version === 'comment' && (
              <div className="canvasContainer">
                <div 
                  ref={this.ref1} 
                  className={`igQuestionBox ${cardClass}`} 
                  onClick={this.toggleCardColor2}
                >
                  <div 
                    className='profileAvatar'
                    style={{ 
                      backgroundImage: avatarUrl ? `url(${avatarUrl})` : 'none',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat'
                    }}
                  ></div>
  
                  <div className="questionData">
                    <div className="askedQuestion">{this.state.questionText}</div>
                    <div className="replyingTo">Replying to {truncatedUsername}</div>
                  </div>
                </div>
                <div className="questionInputs">
                  <div className="inputBox">
                    <label className="inputLabel" htmlFor="question">Question ({questionCharCount} characters)</label>
                    <textarea 
                      id="question"
                      rows="3"
                      cols="50"
                      className='questionInput' 
                      value={inputQuestionText}
                      onChange={this.handleInputChange} 
                      placeholder={this.state.inputQuestionText}
                    />
                  </div>
                  <div className="inputBox">
                    <label className="inputLabel" htmlFor="username">Username</label>
                    <input 
                      id="username"
                      type="text"
                      className='userInput' 
                      value={this.state.username} 
                      onChange={this.handleUsernameChange} 
                      placeholder="Enter username" 
                    />
                  </div>
                  <div className="inputBox">
                    <label className="inputLabel" htmlFor="avatar">Profile Photo</label>
                    <input 
                      id="avatar"
                      type="file"
                      className='avatarInput' 
                      onChange={this.handleAvatarChange} 
                    />
                    {/* {avatarUrl && <img src={avatarUrl} alt="Profile Preview" className="avatarPreview" />} */}
                  </div>
                  <canvas ref={this.canvasRef} style={{ display: 'none' }} width="50" height="50"></canvas>
                </div>
              </div>
            )}
  
            {version === 'question' && (
              <div className="canvasContainer">
                <div 
                  ref={this.ref2} 
                  className="igQuestionBox2"
                >
                  <div className="questionData2">
                    <div className="questionTitle">{this.state.questionTitle}</div>
                    <div className="askedQuestion2">{this.state.questionResponse}</div>
                  </div>
                </div>
                <div className="questionInputs">
                  <div className="inputBox">
                    <label className="inputLabel" htmlFor="questionTitle">Question Title</label>
                    <input 
                      id="questionTitle"
                      type="text"
                      className='userInput' 
                      value={this.state.questionTitle} 
                      onChange={this.handleQuestionTitle} 
                      placeholder="What is your Question" 
                    />
                  </div>
                  <div className="inputBox">
                    <label className="inputLabel" htmlFor="question">Question</label>
                    <textarea 
                      id="question"
                      rows="3"
                      cols="50"
                      className='questionInput' 
                      value={questionResponse}
                      onChange={this.handleQuestionResponse} 
                      placeholder={this.state.questionResponse}
                    />
                  </div>
                  <canvas ref={this.canvasRef2} style={{ display: 'none' }} width="50" height="50"></canvas>
                </div>
              </div>
            )}
  
            {version === 'tiktok' && (
              <div>
              <div className="tokContainer" ref={this.ref3} >
                <div className="tokComment tokCommentBox">
                  <div 
                      className='tokProfileAvatar'
                      style={{ 
                        backgroundImage: avatarUrl ? `url(${avatarUrl})` : 'none',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                      }}
                    ></div>
                  <div className="tokCommentData">
                    <div className="tokReplyingTo">Reply to {this.state.tokReply}'s comment</div>
                    <div className="tokCommentQuestion">
                      {this.state.tokQuestion}
                    </div>
                  </div>
                  <canvas ref={this.canvasRef3} style={{ display: 'none' }} ></canvas>
                  <div className="tokTail"><img src={tokTail} /></div>
                </div>
              </div>
                <div className="questionInputs">
                      <div className="inputBox">
                        <label className="inputLabel" htmlFor="question">Question ({questionCharCount} characters)</label>
                        <textarea 
                          id="question"
                          rows="3"
                          cols="50"
                          className='questionInput' 
                          value={tokQuestion}
                          onChange={this.handleTokQuestion} 
                          placeholder={this.state.tokQuestion}
                        />
                      </div>
                      <div className="inputBox">
                        <label className="inputLabel" htmlFor="username">Username</label>
                        <input 
                          id="username"
                          type="text"
                          className='userInput' 
                          value={this.state.tokReply} 
                          onChange={this.handleTokReply} 
                          placeholder="Enter username" 
                        />
                      </div>
                      <div className="inputBox">
                        <label className="inputLabel" htmlFor="avatar">Profile Photo</label>
                        <input 
                          id="avatar"
                          type="file"
                          className='avatarInput' 
                          onChange={this.handleAvatarChange} 
                        />
                      </div>
                    </div>
              </div>
            )}

          {this.state.version === "threadPost" && (
                    <div className="canvasContainer">
                      <div 
                      ref={this.ref4} 
                      className="threadsPostBox"
                      onClick={this.toggleCardColor}
                      style={{
                        backgroundColor: this.state.isWhiteCard ? '#ffffff' : '#101010',
                        color: this.state.isWhiteCard ? '#000000' : '#ffffff'
                      }}
            >
              <div className="threadUserBox">
                <div
                  className="profileAvatarThreads"
                  style={{
                    backgroundImage: avatarUrl ? `url(${avatarUrl})` : "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
                <div className="threadsUser">{truncatedUsername}</div>
                {this.state.showThreadCheckmark && (
                  <img src={threadCheck} className="threadCheck" alt="verified" />
                )}
                <div className="threadTime">4h</div>
              </div>

              <div className="threadContent threadContentDisplay" style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}>
                {threadContent.split('\n').map((item, index) => (
                  <span key={index}>
                    {item}
                    <br style={{ lineHeight: "2.5" }} />
                  </span>
                ))}
              </div>

                  {this.state.showThreadMetrics && (
                    <div className="threadMetrics">
                      <img src={this.state.currentLikeIcon} className="threadsMetricIcons" alt="likes" />
                      <div className="threadMetricNumber">{threadLikes}</div>
                      <img src={this.state.currentCommentIcon} className="threadsMetricIcons" alt="comments" />
                      <div className="threadMetricNumber">{threadComments}</div>
                      <img src={this.state.currentRepostIcon} className="threadsMetricIcons" alt="reposts" />
                      <div className="threadMetricNumber">{threadReposts}</div>
                      <img src={this.state.currentSendIcon} className="threadsMetricIcons" alt="sends" />
                      <div className="threadMetricNumber">{threadSends}</div>
                    </div>
                  )}
            </div>

            <div className="questionInputs">

              <div className="toggleBox">
                    <button onClick={this.toggleThreadMetrics} className="toggleBtn">
                      {this.state.showThreadMetrics ? 'Hide Metrics' : 'Show Metrics'}
                    </button>

                    <button onClick={this.toggleThreadCheckmark} className="toggleBtn">
                      {this.state.showThreadCheckmark ? 'Hide Checkmark' : 'Show Checkmark'}
                    </button>
              </div>

              <div className="inputBox">
                <label className="inputLabel" htmlFor="threadContent">
                  Thread Content
                </label>
                <textarea
                  id="threadContent"
                  rows="3"
                  cols="50"
                  className="questionInput threadTextarea"
                  value={threadContent}
                  onChange={(e) => {
                    this.handleThreadContentChange(e);
                    this.adjustTextareaHeight(e);
                  }}
                  placeholder="Enter thread content"
                />

              </div>
              <div className="inputBox">
                <label className="inputLabel" htmlFor="threadLikes">
                  Likes
                </label>
                <input
                  id="threadLikes"
                  type="number"
                  className="userInput"
                  value={threadLikes}
                  onChange={this.handleThreadLikesChange}
                  placeholder="Enter number of likes"
                />
              </div>
              
              <div className="inputBox2">
                <label className="inputLabel" htmlFor="threadComments">
                  Comments
                </label>
                <div className="circleContainer">
                  <button className="minusButton" onClick={this.decreaseThreadComments}>
                    -
                  </button>
                  <input
                    type="text"
                    className="numberInput"
                    value={threadComments}
                    onChange={this.handleInputChange2}
                  />
                  <button className="plusButton" onClick={this.increaseThreadComments}>
                    +
                  </button>
                </div>
              </div>


              <div className="inputBox">
                <label className="inputLabel" htmlFor="threadReposts">
                  Reposts
                </label>
                <input
                  id="threadReposts"
                  type="number"
                  className="userInput"
                  value={threadReposts}
                  onChange={this.handleThreadRepostsChange}
                  placeholder="Enter number of reposts"
                />
              </div>
              <div className="inputBox">
                <label className="inputLabel" htmlFor="threadSends">
                  Sends
                </label>
                <input
                  id="threadSends"
                  type="number"
                  className="userInput"
                  value={threadSends}
                  onChange={this.handleThreadSendsChange}
                  placeholder="Enter number of sends"
                />
              </div>
              <div className="inputBox">
                <label className="inputLabel" htmlFor="username">
                  Username
                </label>
                <input
                  id="username"
                  type="text"
                  className="userInput"
                  value={this.state.username}
                  onChange={this.handleUsernameChange}
                  placeholder="Enter username"
                />
              </div>
              <div className="inputBox">
                <label className="inputLabel" htmlFor="avatar">
                  Profile Photo
                </label>
                <input id="avatar" type="file" className="avatarInput" onChange={this.handleAvatarChange} />
              </div>
            </div>
          </div>
        )}

        {this.state.version === "xPost" && (
                    <div className="canvasContainer">
                            <div 
                            ref={this.ref5} 
                            className="threadsPostBox"
                            onClick={this.toggleCardColor}
                            style={{
                              backgroundColor: this.state.isWhiteCard ? '#ffffff' : '#101010',
                              color: this.state.isWhiteCard ? '#000000' : '#ffffff'
                            }}
                            >

                                <div data-theme="dark">
                                  <Tweet id={this.state.tweetId} />
                                </div>
                        
                            </div>
                    </div>
        )}
          </div>
          <button className="dlButton" onClick={this.downloadImage}><img src={downloadIcon}/></button>
        
        
        <div className="footerContainer">
          <div className="footerLinks">
            
            



            <a href="https://instagram.com/thestevenmellor">Follow on Instagram</a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://threads.net/@thestevenmellor">Follow on Threads</a>
            
          </div>
        </div>
        </div>
  
        
      </div>
    );
  }
  
}
