import '../App.css';
import React, { Component, useEffect } from "react";

import Plausible from 'plausible-tracker'

import {Helmet} from "react-helmet";
import featureEvent from '../assets/dpcover.png';
import aabundle from '../assets/aabundle.png'
import zulu100K from '../assets/z100K.png';
import kcreator from '../assets/100k-creator.png';
import boltIcon from '../assets/boltIcon.svg';
import fdico from '../assets/fdicon.png';
import mcico from '../assets/mcicon.png';
import sjmface from '../assets/sjmface.png';
// Import the functions you need from the SDKs you need
import storiesCard from "../assets/storiesCard.png";

var Buffer = require('buffer/').Buffer 

const loomSrc="https://www.loom.com/embed/0a20f4da4bcf494da021654068e2b794"
const loomParams="?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"



const loomEmbed = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

const loomFrame = {
  position: 'relative',
  paddingBottom: '64.67065868263472%', 
  height: 0,
}


const fdKey = process.env.REACT_APP_FD_API_KEY






// Initialize Firebase
// const app = initializeApp(firebaseConfig);

export default class Home extends Component {


  constructor(props) {
    super(props);

    this.state = {
      value: '',
      email: '',
      error: '',
      isValidEmail: true,
      submitted: false,
      isLoading: false,
      submittedSuccessfully: false, // Add this state variable
    }
      
  }


  componentDidMount(){
    // this.getSegments();
    // console.log();
  }




  

  render() {
    const { email, isValidEmail, isLoading, submittedSuccessfully } = this.state;
    const inputStyle = {
      border: isValidEmail ? '1px solid #4a4a4a' : '1px solid #FF4343',
    };

    return (

      <div>
         <Helmet>
                
                
                <meta charSet="utf-8" />
                <title>Steven Mellor | Automated Creator | Helping you monetize and automate your Personal Brands</title>
                <link rel="canonical" href="http://thestevenmellor.com/" />
      
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://thestevenmellor.com/" />
                <meta property="og:title" content="Steven Mellor / The Automated Creator" />
                <meta property="og:description" content="Helping you monetize and automate your Personal Brands" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/mellorlink.appspot.com/o/acThumb1.png?alt=media&token=40d4a7eb-59de-4fa0-a261-f7e0c86df374" />
      
               
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://thestevenmellor.com/" />
                <meta property="twitter:title" content="Steven Mellor / The Automated Creator" />
                <meta property="twitter:description" content="Helping you monetize and automate your Personal Brands" />
                <meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/mellorlink.appspot.com/o/acThumb1.png?alt=media&token=40d4a7eb-59de-4fa0-a261-f7e0c86df374" />
      
            
                     
            </Helmet>
      
            <div className="snapBar">
              <div className='snapLogo'>
                {/* <img src={srLogo2} className='desktopLogo' />
                <img src={srLogo} className='mobileLogo' /> */}
              </div>
              {/* {submittedSuccessfully ? (
                  <div className="thankYouMessage">You're In! Sending you an email now</div>
                ) : (
                  <form onSubmit={this.handleSubmit} className="waitlistForm2">
                    <div className="inputContainer">
                      <input
                        type="email"
                        value={email}
                        className={this.getInputClass()}
                        placeholder="Subscribe for free growth tips"
                        onChange={this.handleEmailChange}
                        style={inputStyle}
                      />
                      <button className="featureBtn2" type="submit">
                        {isLoading ? <div className="loader"></div> : 'Subscribe'}
                      </button>
                    </div>
                  </form>
                )}


              <div className="pageTitle">SnapReply</div> */}
            </div>

        <div className="mainContWeby">
          <div className="heroMessage gradient-text">4 Proven Story Automations To Drive More Leads and Sales</div>
          {/* <div className="heroSubmessage">building in public — documenting everything</div> */}
          <div className='spacer30'></div>
          <div style={loomFrame}><iframe src={loomSrc+loomParams} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={loomEmbed}></iframe></div>
          <div className="heroMessage2">How to implement these</div>
        </div>
        </div>
    );
    }
}




