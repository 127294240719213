import '../App.css';
import './Zero.css';
import React, { Component, useEffect } from "react";
import {Helmet} from "react-helmet";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';



import zulu100K from '../assets/zero100k.png';
import heroGraphic from '../assets/services-hero-dark.png';
import heroGraphic2 from '../assets/dm-copilot-hero-white.png';

import circleMG from '../assets/matt-gray-circle.png';
import circleDJ from '../assets/devin-jatho-circle.png';
import circleSO from '../assets/sean-oulashin-circle.png';
import circleHN from '../assets/heidi-neal-circle.png';
import circleAW from '../assets/angie-ward-circle.png';
import circleJP from '../assets/jimmy-pierat-circle.png';
import circleTN from '../assets/tom-noske-circle.png';
import starRow from '../assets/stars2.png';
import dmcopilotlogo from '../assets/dm-copilot-logo-light.svg';
import dmBadge from '../assets/dmbadge.svg';

import storyIcon from '../assets/storyicon.png';
import reelIcon from '../assets/reelicon.png';
import emailIcon from '../assets/emailicon.png';
import captionIcon from '../assets/captionicon.png';
import dmIcon from '../assets/sendicon.png';
import lightningIcon from '../assets/lightningIcon.svg';
import boltIcon from '../assets/boltIcon.svg';
import storyTemps from '../assets/story-templates.png';
import mcFlows from '../assets/dmFlows.png';
import emTemps from '../assets/em-template.png';
import boxFront from '../assets/box-front.png';
import boxFrontSale from '../assets/box-sale-front.png';
import boxAngle from '../assets/box-angle.png';
import saleNotifications from '../assets/sale-notifications.png';
import newLogo from "../assets/ac-white-logo.svg";

import ex1 from '../assets/ex2a.png';
import ex2 from '../assets/ex1a.png';

import handarrow from "../assets/arrow.png";

import socialProof from "../assets/socialProof.png";
import proofdm4 from "../assets/proofdm4.png";
import tnTest from "../assets/tn-testimonial.png";
import mgTest from "../assets/storyProof1.png";
import squiggleLine from "../assets/squiggle.svg";
import acThumb from "../assets/acThumb1.png";
import { getAnalytics, logEvent } from "firebase/analytics";

import { v4 as uuidv4 } from 'uuid';

const analytics = getAnalytics();




const randomizeTitle = Math.random() < 0.5;


export default class Zero extends Component {


  constructor(props) {
    super(props);

    this.state = {
      value: '',
      error: '',
      email: '', 
      remaining: '',
      activeTab: 1,
      invalidForm: false,
      screenWidth: window.innerWidth,
      randomTitle: false,
      lastUpdated: ' ',
    }

  }

  getUserId = () => {
    let userId = localStorage.getItem('userId');
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem('userId', userId);
      console.log('userId: ', userId);
    }
    return userId;
  };

  
  getHeadline = () => {
    const currentHour = new Date().getHours(); // Gets the current hour (0-23)
    
    if (currentHour % 2 === 0) {
      // Even hours
      console.log("current Hour is even: ", currentHour);
      console.log("randomTitle State", this.state.randomTitle);
      return this.setState({randomTitle: false})
    } else {
      // Odd hours
      console.log("current Hour is odd: ", currentHour);
      console.log("randomTitle State", this.state.randomTitle);
      return this.setState({randomTitle: false})
    }
  };

  componentDidMount(){
    // this.tcCheckout();
    this.percentageOfDayRemaining();
    this.getHeadline();
    this.getUserId();
    window.addEventListener('resize', this.updateScreenWidth);

  }

  calculateDateDifference = (date1, date2) => {
    const timeDiff = date2.getTime() - date1.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

  componentWillUnmount() {
    // Remove the resize event listener when the component is unmounted to prevent memory leaks.
    window.removeEventListener('resize', this.updateScreenWidth);

     
  }

  updateScreenWidth = () => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  };



 


  

  percentageOfDayRemaining() {
    
    const randoNu = Math.floor(Math.random() * 3);
    const now = new Date();
    const totalMinutesInDay = 24 * 60;
    const minutesPassed = now.getHours() * 60 + now.getMinutes();
    const minutesRemaining = totalMinutesInDay - minutesPassed;
    const percentageRemaining = (minutesRemaining / totalMinutesInDay) * 100;
    const finalRem = Math.round(percentageRemaining) + randoNu;
    return this.setState({ 
      remaining: finalRem,
    });
}


handleTabClick = (tabNumber) => {
  this.setState({ activeTab: tabNumber });
};

toggleRow1 (rowId){
  let tr1 = document.getElementById(rowId);
  // let tr1Arrow = document.getElementById('tr1arrow');
  // let tr1Arrow2 = document.getElementById('tr1arrow2');
  tr1.classList.toggle("sfActive");
  // tr1Arrow.classList.toggle('act');
  // tr1Arrow2.classList.toggle('act2');
}

jumpTo(rt, pos){
console.log(rt);  
    const targetElement = document.getElementById(rt);
  this.logConv(pos);
  if (targetElement) {
    // Scroll to the target element
    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

logConv(pos){
  logEvent(analytics, pos);
  return console.log('fired event: ', pos);
}


  render() {

    const { email, error, invalidForm, activeTab, screenWidth } = this.state;
    
    const NuRem = this.state.remaining;

     // The date when the page was last updated
     const lastUpdatedDate = new Date('2024-2-07');

     // Current date
     const currentDate = new Date();
 
     // Calculate the difference in days
     const daysAgo = this.calculateDateDifference(lastUpdatedDate, currentDate);
 

  return (
    <div>
      <Helmet>
                
                
          <meta charSet="utf-8" />
          <title>Automated Creator: Turn Instagram into your Automated Sales Machine | Steve Mellor</title>
          <link rel="canonical" href="http://thestevenmellor.com/automatedcreator" />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://thestevenmellor.com/automatedcreator" />
          <meta property="og:title" content="Automated Creator" />
          <meta property="og:description" content="Turn Instagram into your Automated Sales Machine | Steve Mellor" />
          <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/mellorlink.appspot.com/o/acThumb1.png?alt=media&token=40d4a7eb-59de-4fa0-a261-f7e0c86df374" />

         
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://thestevenmellor.com/automatedcreator" />
          <meta property="twitter:title" content="Automated Creator" />
          <meta property="twitter:description" content="Turn Instagram into your Automated Sales Machine | Steve Mellor" />
          <meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/mellorlink.appspot.com/o/acThumb1.png?alt=media&token=40d4a7eb-59de-4fa0-a261-f7e0c86df374" />

      
               
      </Helmet>
      
    
      <div className="mainCont2 dark2">
      {/* <div className="notiBar" onClick={()=>this.jumpTo('pricingSect', 'buy_banner')}>🎉 Take $150 off Automated Creator until 2/2  🎉</div> */}
        <div className='floatingNav'>
          {/* <div className='fnLogo'>Automated Creator</div> */}
          <img className='fnLogo2' src={newLogo} />
          <div className="navLink" onClick={()=>this.jumpTo('featSect', 'nav_features')}>Features</div>
          <div className="navLink" onClick={()=>this.jumpTo('demoSect', 'nav_demo')}>Demo</div>
          <div className="navLink" onClick={()=>this.jumpTo('pricingSect', 'nav_pricing')}>Pricing</div>
          
          <a href="#pricingSect" onClick={() => this.logConv('buy_nav')}><div className="floatRight sfButton scalePoint8 hideMobile"><img className="badgeLogo" src={boltIcon} />Unlock Instant Access</div></a>
        </div>
        <div className="marginColumn padTop150 padBottom150">
          <div className='splitColumn rowBasedLeft'>
            {/* <div className="sfH1 mobileCenter darkText">The Best Damn Instagram Marketing Automation System.</div> */}
            {!this.state.randomTitle ?  <div className="sfH1 mobileCenter darkText">Turn Instagram into your Automated Sales Machine</div> : <div className="sfH1 mobileCenter darkText">More views. <br />More leads. <br />More sales <br />— on autopilot.</div>}
            
            <div className="sfBody2 mobileCenter darkText">The complete automation system for busy creators who want more growth and sales on Instagram.  
            </div>
            

            <a href="#pricingSect" onClick={() => this.logConv('buy_hero')}><div className="sfButton justifyLeft"><img className="badgeLogo" src={boltIcon} />Unlock Instant Access</div></a>

            <div className="sfButtonProof darkText">Pay once. Keep forever.</div>
            
            <div className="proofContainer">
              <div className="circles tooltip">
                <img src={circleAW}/>
                <span class="tooltiptext">@angiesfitfix</span>
              </div>
              {/* <a href="https://www.instagram.com/seanoulashin/" target="_new"><div className="circles tooltip">
                <img src={circleSO}/>
                <span class="tooltiptext">@seanoulashin</span>
              </div></a> */}
              <div className="circles tooltip">
                <img src={circleHN}/>
                <span class="tooltiptext">@heidinealfitness</span>
              </div>
              <div className="circles tooltip">
                <img src={circleTN}/>
                <span class="tooltiptext">@tomnoske</span>
              </div>
              <div className="circles tooltip">
                <img src={circleJP}/>
                <span class="tooltiptext">@wildhuntconditioning</span>
              </div>
              <div className="circles tooltip">
                <img src={circleDJ}/>
                <span class="tooltiptext">@devinjatho</span>
              </div>
              <div className="circles tooltip">
                <img src={circleMG}/>
                <span class="tooltiptext">@matthgray</span>
              </div>
              <div className="circleswhite">
                +210 creators<br />
                <img className="starRow" src={starRow} />
                
              </div>
             
            </div>



          </div>

          <div className='splitColumn'>
            <div className='heroImage'>
              {/* <img src={heroGraphic2} /> */}
              <img src={boxFrontSale} />
            </div>
          </div>

        </div>

       


        <div className="fullColumn dark">
            <div className="marginColumn maxW600 ">
              <div className="singleColumn rowBased">
              <div  id="featSect" className="sfH2 centerText">Watch how my clients are getting <u>30,000+ views</u> on Stories and collecting 3,000 emails in 24 hours.</div>
                    <div  id="demoSect2" className="testHand centerText padTop50 padBottom50 demoLoom">Tons of lessons like this!<span className='handArrow2'><img src={handarrow} /></span></div>
                  <iframe src='https://www.loom.com/embed/447c56b6adb7492c90ac062ad5c14dc3?sid=7faa75cb-8fff-4085-93e5-6112cc5c0dce&hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true'
                            frameborder='0'
                            // width="100%"
                            // height="600px"
                            className="loomPlayer"
                            allow='autoplay; encrypted-media'
                            allowfullscreen
                            title='video'
                  />

                   <div className="fullColumn dark squiggle">
                    <img src={squiggleLine} width={200}/>
                  </div>

                  <div  id="featSect" className="sfH2 centerText">Turn followers into Customers — <i>while you sleep</i></div>
                  <div className="sfBody centerText">With the Automated Creator System. — it's not <i>just</i> DMs — it's the entire communication process from <strong>Content</strong> through to <strong>Email.</strong></div>
                  <div className="benesBox ">
                  <mark>Earn While You Sleep: </mark>Transform your followers into customers even when you're offline. Our automation ensures you're making sales and capturing leads 24/7, turning your social media into a revenue-generating machine.<br /><br />
                  <mark>No More Manual DMs:</mark> With Instagram DM Automation, you can engage your followers instantly and consistently, saving countless hours.<br /><br />
                  <mark>Engaging Stories in Minutes:</mark> Pre-designed Instagram Story templates let you create captivating content quickly, increasing views without the time drain.<br /><br />
                  <mark>Effortless Email Campaigns:</mark> Ready-to-use email templates streamline your communication, cutting down the 4-hour setup to mere minutes.<br /><br />
                  <mark>Easy Integration:</mark> One-click Installs for all DM automation flows.<br /><br />
                  <mark>Viral Engagement:</mark> Each template is designed not just to save time, but to boost engagement and growth on your platforms.<br /><br />
                  </div>
                  <div className="sfBody">let me show you how 👇</div>
                  <a href="#pricingSect"  onClick={() => this.logConv('buy_f2c')}><div className="sfButton"><img className="badgeLogo" src={boltIcon} />Unlock Instant Access</div></a>
              </div>
            </div>
        </div>

        <div className="fullColumn dark squiggle">
          <img src={squiggleLine} width={200}/>
        </div>


        <div  className="fullColumn dark">
            <div className="marginColumn  maxW600 rowBased  ">
                
          
                <div className="sfH2  mobileCenter ">So who exactly is this for?</div>
                <div className="sfBody centerText padBottom50">I originally built this for me and my business as a busy solo creator. <i>However</i>, I knew I had something <i>reeeeaally good.</i> So I set out to stress test it across a bunch of different use cases and creator businesses.
                    <br /><br />
                    <i>annnnd it worked flawlessly — shocker.</i>
                    <br /><br />
                    Check out if it's right for you 👇
                </div>
                
                <div className="sfH4"><span className="blue">Busy Creators & Entrepreneurs</span></div>
                <div className="sfBody  padBottom50">
                You're shaping the future in marketing, AI, fitness, or design, but the one thing we can’t get back — is time. This system slashes the hours you spend on managing the messy parts of Instagram giving you the freedom to focus on what really counts - growing your business and engaging deeply with your audience (or being able to dip out of work for your kids basketball game — been there, definitely done that).
                </div>

                <div className="sfH4 "><span className="blue">Side Hustler wanting more time?</span></div>
                <div className="sfBody  padBottom50">
                Ready to ditch the 9-to-5 and go full throttle at this whole creator thing? Hell yea, I’m done that — it’s wild. But, whether you’re just starting to gain traction or looking to monetize a booming follower base, this product is your blueprint to turn those followers into customers — automatically
                </div>

                <div className="sfH4 "><span className="blue">Running Solo Dolo as a creator</span></div>
                <div className="sfBody  padBottom50">
                No team? No problem. I’m not much for the big teams and managing people either. This solution is perfect for the one-person powerhouse. Create, automate, and convert with a tool that’s as ambitious as you are - all without needing a big team or deep pockets.</div>

                <div className="sfH4 "><span className="blue">The Digital Product Seller</span></div>
                <div className="sfBody  padBottom50">New to the monetization game? or been here a while but want to make it better? — This is my jam! This will guide you through the maze. Learn to craft compelling content, automate your workflows, and transform your followers into buyers, all while keeping it genuine and uniquely you.</div>
                
                <div className="sfH4 "><span className="blue">Reminder</span></div>
                <div className="sfBody  padBottom50">You're not just getting a tool; you’re getting a <strong>bonafide automated lead printer</strong> that’s changing how people build and grow their Instagram businesses. Be bold, be different, and let’s make your dream a thriving  (automated) reality.
                </div>
            </div>
        </div>

        <div className="fullColumn dark squiggle">
          <img src={squiggleLine} width={200}/>
        </div>


        <div  id="featSect" className="fullColumn dark">
            <div className="marginColumn  rowBased padTop50 padBottom50">
                <div className="sfH2 centerText padBottom50">This gets me amped up 👇</div>
                
                <div className="proof2"><img src={tnTest} /></div>
            </div>
        </div>

        <div className="fullColumn dark squiggle">
          <img src={squiggleLine} width={200}/>
        </div>


        <div  id="featSect" className="fullColumn dark">
            <div className="marginColumn maxW600 rowBased padTop150 padBottom50">
                  <div className="sfH2 centerText">More Views. More Leads. More Sales.</div>
                  <div className="sfBody centerText">Not only does this system help you reclaim your life (our customers save an average of ~12 hours per week) but they'll also improve <mark>engagement</mark> and <mark>conversions</mark> across the board!</div>
                  
                  <div className="buffer50"></div>
            <Tabs>
              <TabList>
                <Tab><img src={storyIcon} width={50}/></Tab>
                <Tab><img src={captionIcon} width={50}/></Tab>
                <Tab><img src={reelIcon} width={50}/></Tab>
                <Tab><img src={dmIcon} width={50}/></Tab>
                <Tab><img src={emailIcon} width={50}/></Tab>
              </TabList>

              <TabPanel>
                <div className="sfH3">Stories</div>
                <div className="sfBody">
                  <strong>Leverage our powerful Stories training and templates to:</strong><br /><br />
                  ✅ Get more Story views<br />
                  ✅  Reach up to 25% of your audience<br />
                  ✅  Get more reactions<br />
                  ✅  Get more DMs<br /><br />
                  
                  
                </div>
              </TabPanel>
              <TabPanel>
                <div className="sfH3">Captions</div>
                <div className="sfBody">
                <strong>Learn how to write high performing captions that convert!</strong><br /><br />
                  ✅  Get people to read your captions<br />
                  ✅  ChatGPT prompts to write for you<br />
                  ✅  Library of pre-made captions<br />
                  
                  
                </div>
              </TabPanel>
              <TabPanel>
                <div className="sfH3">Reels</div>
                <div className="sfBody">
                <strong>Learn powerful techniques to 5x your Reels engagement</strong><br /><br />
                  ✅  Drive more comments<br />
                  ✅  Exploit the algorithm<br />
                  ✅  Reach more people<br />
                
                  
                  
                </div>
              </TabPanel>
              <TabPanel>
                <div className="sfH3">DMs</div>
                <div className="sfBody">
                <strong>Master the art of the DM</strong><br /><br />
                  ✅  Save 2+ hrs per day<br />
                  ✅  Network like a pro<br />
                  ✅  Get more opportunites<br />
                  
                 
                </div>
              </TabPanel>
              <TabPanel>
               <div className="sfH3">Email</div>
                <div className="sfBody">
                <strong>Setup your complete creator email system in a day</strong><br /><br />
                  ✅  Over 30+ email templates<br />
                  ✅  Convert followers to buyers<br />
                  ✅  Grow your Email list from the DMs<br />
                  ✅  Diversify your brand<br />
                  
                  
                </div>
              </TabPanel>
            </Tabs>

                  
                
            </div>
        </div>

        <div className="fullColumn dark squiggle">
          <img src={squiggleLine} width={200}/>
        </div>


        <div  id="featSect" className="fullColumn dark">
            <div className="marginColumn  rowBased padTop50 padBottom50">
            <div className="sfH2 centerText padBottom50">More wins 🎉</div>
            
            <div className="proof3"><img src={proofdm4} /></div>
            </div>
        </div>

        <div className="fullColumn dark squiggle">
          <img src={squiggleLine} width={200}/>
        </div>


        <div className="fullColumn dark">
            <div className="marginColumn maxW600 rowBasedLeft  padTop50 padBottom50">
            <div className="sfH2 centerText padTop50 padBottom50">What's included</div>
            <div className="sfBody centerText">I want you to have everything you need to run this program Day 1. That's why I've included over 100+ done-for-you Story Templates, Email Flows, DM Automation Funnels, Notion Guides, and more!</div>

            <div  id="demoSect" className="testHand centerText padTop50 padBottom50 demoLoom">watch the demo!<span className='handArrow'><img src={handarrow} /></span></div>
            <iframe src='https://www.loom.com/embed/b6266e81e1e246deba13ed684521f581?sid=381e7df4-f733-46f3-8785-323b3ce12887&hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true'




                      frameborder='0'
                      // width="100%"
                      // height="600px"
                      className="loomPlayer "
                      allow='autoplay; encrypted-media'
                      allowfullscreen
                      title='video'
              />
              
            {/* <div className="sfH3 padTop50 centerText">Over 65 perfectly designed Story Templates</div>
            <img src={storyTemps} />
            <div className="sfH3 padTop50 centerText">Over 10 conversion-ready ManyChat flows</div>
            <img src={mcFlows} /> */}
        <div className="fullColumn dark padTop50">
            <div className="marginColumn maxW600 rowBased">
                <a href="#pricingSect"  onClick={() => this.logConv('buy_demo')}><div className="sfButton"><img className="badgeLogo" src={boltIcon} />Unlock Instant Access</div></a>
            </div>
         </div>
 
        <div className="fullColumn dark squiggle">
          <img src={squiggleLine} width={200}/>
        </div>

            <div className="sfH2 centerText gold ">What You Get</div>
            <div className="sfH5 centerText gold ">Course Curriculum</div>
            <div className="sfModule">
                <div className="sfH2 padTop50 centerText"><span className="sfNo">01.</span> Introduction</div>
                <div className="sfBody centerText  padBottom50">
                  • Quick Walkthrough<br />
                  • My Background<br />
                </div>
            </div>
            
            <div className="sfModule">
                <div className="sfH2 padTop50 centerText"><span className="sfNo">02.</span> How To Reach 1 Million People Each Month</div>
                <div className="sfBody centerText  padBottom50">
                  • 2 things holding you back<br />
                  • Working with the Algorithm<br />
                  • How to use the Content Funnel<br />
                  • Content that working right now<br />
                  • If I were starting over today (Content)<br />
                </div>
            </div>

            <div className="sfModule">
                <div className="sfH2 padTop50 centerText"><span className="sfNo">03.</span> 6 Places You Need to Automate</div>
                <div className="sfBody centerText  padBottom50">
                  • Why DM automation works<br />
                  • Mistakes creators make with automation<br />
                  • Where and when to use it<br />
                  • 6 Places to deploy automation<br />
                  • How to write high-converting Captions<br />
                  • How to use the mid-roll CTA<br />
                  • Crafting the perfect bio<br />
                  • How to sell in your sleep<br />
                </div>
            </div>

            <div className="sfModule">
                <div className="sfH2 padTop50 centerText"><span className="sfNo">04.</span> The Automation Creator System</div>
                <div className="sfBody centerText  padBottom50">
                  • The Tool stack I recommend<br />
                  • ManyChat Deep Dive<br />
                  • Setting up Zapier with ManyChat<br />
                  • Setting up Make with ManyChat<br />
                  • Leveraging ChatGPT with your DMs<br />
                  • Integrating with Google Sheets<br />
                  • How to track campaigns<br />
                </div>
            </div>

            <div className="sfModule">
                <div className="sfH2 padTop50 centerText"><span className="sfNo">05.</span> Stories to Sales Method</div>
                <div className="sfBody centerText  padBottom50">
                  • How to craft compelling Stories<br />
                  • Story Design and why it matters<br />
                  • Story Templates overview<br />
                  • Triggering Stories<br />
                  • Using the Design Templates<br />
                  • Setting up your first Story Flow<br />
                  • How to 10x your views <br />
                  • How to get Sales from Stories<br />
                  • Evergreen Story Stack <br />
                </div>
            </div>

          

            <div className="sfModule">
                <div className="sfH2 padTop50 centerText"><span className="sfNo">06.</span> Case Studies</div>
                <div className="sfBody centerText  padBottom50">
                  • Case Study 1: Content Creator<br />
                  • Case Study 2: Matt Gray<br />
                  • Case Study 3: Toozer Strategy Breakdown<br />
                  • Case Study 4: Tom Noske breakdown<br />
                  • Case Study 4: How I run my system<br />
                </div>
            </div>

            <div className="sfModule">
                <div className="sfH2 padTop50 centerText"><span className="sfNo">07.</span> How to Create and Sell Digital Products Course</div>
                <div className="sfBody centerText  padBottom50">
                  • What are lead magnets and how to use them<br />
                  • Building your Lead Magnet<br />
                  • Creating a High Converting Landing Page<br />
                  • Building a value vault<br />
                  • 27 Digital Product Ideas and Templates<br />
                </div>
            </div>

            <div className="sfModule">
                <div className="sfH2 padTop50 centerText"><span className="sfNo">08.</span> Email Marketing Mastery Course</div>
                <div className="sfBody centerText  padBottom50">
                  • Complete Email Course for Creators walkthrough<br />
                  • How to setup your Flodesk account<br />
                  • How to connect ManyChat to Flodesk<br />
                  • 32+ Email Templates and funnels in Notion<br />
                </div>
            </div>

            <div className="sfModule">
                <div className="sfH2 padTop50 centerText"><span className="sfNo">09.</span> 100+ Templates</div>
                <div className="sfBody centerText  padBottom50">
                  • <strong>65+ Story Templates</strong><br />
                  •  <strong>32 Email Templates</strong><br />
                  •  <strong>12 ManyChat Funnels</strong><br />
                  • Discord Community Access<br />
                  • A library of Notion Assets<br />
                  • New templates added Monthly
                </div>
            </div>

          </div>
         
        </div>

        <div className="fullColumn dark">
            <div className="marginColumn maxW600  rowBased">
            <div className="sfBody">Get Access to all of this — <i>Instantly</i> 👇</div>
            <a href="#pricingSect" onClick={() => this.logConv('buy_curriculum')}><div className="sfButton"><img className="badgeLogo" src={boltIcon} />Unlock Instant Access</div></a>
            </div>
        </div>
        
        <div className="fullColumn dark squiggle">
          <img src={squiggleLine} width={200}/>
        </div>

        

        <div className="fullColumn dark">
            <div className="marginColumn rowBased padBottom50">
            
              <div className="sfH5 centerText gold">Testimonials</div>
              <div className="sfH2 justifyCenter padBottom50">Stellar results from automated creators!</div>
              <div className="proof"><img src={socialProof} /></div>
              {/* <div className="senja-embed senja" data-id="972c16c1-a51b-4389-835d-21bba56ef8aa" data-lazyload="false"></div> */}
              {/* <script async type="text/javascript" src="https://static.senja.io/dist/platform.js"></script> */}
              

            </div>
        </div>

        <div className="fullColumn dark squiggle">
          <img src={squiggleLine} width={200}/>
        </div>
        
        <div id="pricingSect" className="fullColumn dark">
            <div className="marginColumn maxW600 rowBased">
              <div className="sfH5 centerText gold">Pricing</div>
              <div className="sfH2 justifyCenter">Turn Instagram into an Automated Lead Printer for your online business</div>
              {/* Supercharge your Creator Business — <br />with the Automated Creator System */}
            </div>
        </div>
        <div className="fullColumn dark">
            <div className="marginColumn maxW600  padBottom50">
            
            
                <div className='splitColumn rowBasedLeft'>

                    <div className="card  shadow-xl dark3 ">
                      <div className="card-body">
                      <div className="sfTitle">🎉 Save $150 and get 5 courses</div>
                      <div className="sfH4">Automated Creator Bundle</div>
                      <div className="sfH1 "><span className="strikeOut redsy">$399</span>$249<span className="usd">USD</span></div>
                      
                        <p className="goldText  ">✔  Automated Creator Course</p>
                        <p className="goldText  ">✔  Digital Products course</p>
                        <p className="goldText  ">✔  Email for creators course</p>
                        
                        <p>✔  36 Lessons (3+ hrs)</p>
                        <p>✔  75+ Stories templates</p>
                        <p>✔  32 email templates </p>
                        <p>✔  15 ManyChat templates</p>
                        <p>✔  Community Access</p>
                        <p className="goldText  ">✔  Content Creation Course</p>
                        <p className="goldText  ">✔  Viral Reels masterclass</p>
                        <p>✔  AI Prompts for Creators</p>
                        <p>✔  Notion Templates</p>
                        <p>✔  Lifetime Updates 
                          <div className="tooltip gold" data-tip="hello">
                              <button className="btn">Updated {daysAgo} days ago</button>
                          </div>
                        </p>
                        <div className="card-actions justify-end">
                          <a href="https://thestevenmellor.thinkific.com/enroll/2782956?price_id=3612582&coupon=take50off" target="_new" className="sfButton2"  onClick={() => this.logConv('buy_bundle')}><img className="badgeLogo" src={boltIcon} />Unlock Instant Access</a>
                        </div>
                        <div className="ctaText centerText">Pay once. Keep forever.</div>
                      </div>
                    </div>
                    
                </div>
                <div className='splitColumn rowBasedLeft'>

                    <div className="card  shadow-xl dark3 ">
                      <div className="card-body">
                      {/* <div className="sfTitle">🎉 Save $150 limited time</div> */}
                      <div className="sfH4">Automated Creator Only</div>
                      <div className="sfH1 "><span className="strikeOut redsy">$349</span>$199<span className="usd">USD</span></div>
                      
                        <p className="goldText  ">✔  Automated Creator Course</p>
                        <p className="goldText  ">✔  Digital Products course</p>
                        <p className="goldText  ">✔  Email for creators course</p>
                        <p>✔  36 Lessons (3 hours of trainings)</p>
                        <p>✔  75+ Stories templates</p>
                        <p>✔  32 email templates </p>
                        <p>✔  15 ManyChat templates</p>
                        <p>✔  Community Access</p>
                       {/*  <p>✔  AI Prompts for Creators</p>
                        <p>✔  Notion Templates</p>
                        <p>✔  Lifetime Updates</p> */}
                        {/* <p className="grayOut">✔  Digital Products course</p> */}
                        <p className="grayOut">✔  Content Creation Course</p>
                        <p className="grayOut">✔  Viral Reels masterclass</p>
                        <p className="grayOut">✔  AI Prompts for Creators</p>
                        <p className="grayOut">✔  Notion Templates</p>
                        <p className="grayOut">✔  Lifetime Updates</p>
                        <div className="card-actions justify-end">
                          <a href="https://thestevenmellor.thinkific.com/enroll/2538090?coupon=take50off" target="_new" className="sfButton2"  onClick={() => this.logConv('buy_core')}><img className="badgeLogo" src={boltIcon} />Unlock Instant Access</a>
                        </div>
                        <div className="ctaText centerText">Pay once. Keep forever.</div>
                      </div>
                    </div>

                </div>
                {/* <div className='splitColumn rowBasedLeft'> */}

                    {/* <div className="card w-96 bg-base-100 shadow-xl dark4 ">
                      <div className="card-body">
                      <div className="sfTitle">🎉 Special Launch Pricing</div>
                      <div className="sfH4">Done For You</div>
                      <div className="sfH1 "><span className="strikeOut redsy">$4999</span>$3999<span className="usd">USD</span></div>
                        <p>✔  Automated Creator Course</p>
                        <p>✔  All Templates & Funnels</p>
                        <p>✔  I setup everything</p>
                        <p>✔  I Design your Stories</p>
                        <p>✔  I Install the Automations</p>
                        <p>✔  I handle the integrations</p>
                        <p>✔  You approve and publish</p>
                        <p>✔  Results typically in 72 hours</p>
                        <div className="card-actions justify-end">
                          <a href="https://thestevenmellor.notion.site/Stories-on-Autopilot-ee5c3e30854a4fb5b684acdac6ee02a5?pvs=4" className="sfButton2" target="_new"><img className="badgeLogo" src={boltIcon} />Apply Today</a>
                        </div>
                      </div>
                    </div> */}

                {/* </div> */}
              
            </div>
            
        </div>
        {/* <div className="fullColumn dark">
            <div className="marginColumn maxW600 padBottom50 rowBased">
                
                 <div className="card w-96 bg-base-100 shadow-xl dark fullWidthCard">
                      <div className="card-body centerText">
                      
                      <div className="sfH2 ">Like this but Want Results in 24hours?</div>
                      <div className="sfBody ">If you would like our team to setup everything for you (Design your Stories, install and setup all the funnels, integrate it all) so you can get results ASAP — fill out the Typeform below and we’ll be in touch </div>
                      
                        <div className="card-actions justify-end">
                          <a href="https://thestevenmellor.notion.site/Stories-on-Autopilot-ee5c3e30854a4fb5b684acdac6ee02a5?pvs=4" className="sfButton2"  onClick={() => this.logConv('book_call')}><img className="badgeLogo" src={boltIcon} />Book a call today</a>
                        </div>
                      </div>
                    </div>
            </div>
        </div> */}

        <div className="fullColumn dark squiggle">
          <img src={squiggleLine} width={200}/>
        </div>


        <div className="fullColumn dark">
            <div className="marginColumn maxW600 rowBased  ">

              <div className="sfH2 mobileCenter">Frequently Asked Questions</div>
              <div className="sfBody mobileCenter">Have another question? Contact me on <a href="https://instagram.com/thestevenmellor" target='_blank'><mark>Instagram</mark></a> or by <a href="mailto:steve@stevenmellor.com" target='_blank'><mark>email</mark></a></div>


              <div className="sfAccordion">

              <div className="sfAccordionRow" onClick={()=> this.toggleRow1('sfTR0')}>
                  <div className="sfAccordionHeading noselect">Who is this course for exactly?</div>
                  <div className="arrowGroup">
                      <div id="tr1arrow3" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow4" className="arrowSpin2">&nbsp;</div>
                  </div>
                  <div id="sfTR0" className="sfAccordionBody">
                    This course is for you if you use Instagram for your business and you want to generate more leads and sales from Instagram<br />
                    - Busy Creators & Entrepreneurs<br />
                    - Side Hustler wanting more time and freedom<br />
                    - Solo creators and solo operators<br />
                    - Social Media and Brand Managers who manage multiple clients<br />
                    - Digital Product Sellers<br />
                    - Ecom brands <br /><br />
                  If you fit into one of those, this course is for you.
                  </div>
                </div>

                <div className="sfAccordionRow" onClick={()=> this.toggleRow1('sfTR10')}>
                  <div className="sfAccordionHeading noselect">Who should not buy this course?</div>
                  <div className="arrowGroup">
                      <div id="tr1arrow3" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow4" className="arrowSpin2">&nbsp;</div>
                  </div>
                  <div id="sfTR10" className="sfAccordionBody">
                   If you're not using Instagram for your online business, then you shouldn't buy this course. <br />
                   <br />
                   Even if you're only looking to copy all of the funnels, email templates, and Story templates, I'd still purchase this, and treat the course as a bonus.
                  
                  </div>
                </div>

                <div className="sfAccordionRow" onClick={()=> this.toggleRow1('sfTR11')}>
                  <div className="sfAccordionHeading noselect">Do I need digital products to use this?</div>
                  <div className="arrowGroup">
                      <div id="tr1arrow3" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow4" className="arrowSpin2">&nbsp;</div>
                  </div>
                  <div id="sfTR11" className="sfAccordionBody">
                   Nope this is for anyone who wants to get more views, leads, and sales from Instagram. It doesn't rely solely on Digital Products. But, there is an entire course included in this on teaching you how to create your own digital products!
                  
                  </div>
                </div>

              <div className="sfAccordionRow" onClick={()=> this.toggleRow1('sfTR1')}>
                  <div className="sfAccordionHeading noselect">What's included in this?</div>
                  <div className="arrowGroup">
                      <div id="tr1arrow3" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow4" className="arrowSpin2">&nbsp;</div>
                  </div>
                  <div id="sfTR1" className="sfAccordionBody">
                    Automated Creator comes with waaaaaayy more than just templates or a "course" — this is an A-Z Instagram Automation System. I will walk you through setting up every part and even provide all of the templates I use for my clients.<br /><br />
                    
                        <p>✔  Automated Creator Course</p>
                        <p>✔  Email for creators course</p>
                        <p>✔  65+ Stories templates</p>
                        <p>✔  32 email templates </p>
                        <p>✔  10 Automation templates</p>
                        <p>✔  Community Access</p>
                        <p>✔  Digital Products course</p>
                        <p>✔  AI Prompts for Creators</p>
                        <p>✔  Notion Templates</p>
                        <p>✔  Lifetime Updates</p><br />
                    <p> If you're looking to get more views, capture more leads, and generate more sales — this is for you.</p>
                  </div>
                </div>



              <div className="sfAccordionRow"  onClick={()=> this.toggleRow1('sfTR7')}>
                  <div className="sfAccordionHeading noselect">How can I access the Course and Templates</div>
                  <div className="arrowGroup">
                      <div id="tr1arrow3" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow4" className="arrowSpin2">&nbsp;</div>
                  </div>
                  <div id="sfTR7" className="sfAccordionBody">
                    Right after you checkout — you'll be redirected to setup your account in Thinkific (My course hosting platform). Once you setup your account, you'll have lifetime access to all the trainings and templates. I'd recommend making copies of the templates to your own Google Drive or Notion.
                  </div>
                </div>

                <div className="sfAccordionRow"  onClick={()=> this.toggleRow1('sfTR9')}>
                  <div className="sfAccordionHeading noselect">What does this course help me do?</div>
                  <div className="arrowGroup">
                      <div id="tr1arrow3" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow4" className="arrowSpin2">&nbsp;</div>
                  </div>
                  <div id="sfTR9" className="sfAccordionBody">
                   This course teaches you how to turn your Instagram DMs into an Automated Sales Machine. It gives you the systems, automations, and templates so you can set it up once and generate leads while you sleep.
                  </div>
                </div>

                <div className="sfAccordionRow"  onClick={()=> this.toggleRow1('sfTR8')}>
                  <div className="sfAccordionHeading noselect">How long is this course and what format is it in?</div>
                  <div className="arrowGroup">
                      <div id="tr1arrow3" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow4" className="arrowSpin2">&nbsp;</div>
                  </div>
                  <div id="sfTR8" className="sfAccordionBody">
                    The course is made of video and written instruction. It's roughly 90 minutes long and that's broken up into a 45 minute strategy course and 45 minute walkthrough course. 
                  </div>
                </div>
                
                <div className="sfAccordionRow"  onClick={()=> this.toggleRow1('sfTR3')}>
                  <div className="sfAccordionHeading noselect">Do I need a lot of followers to use this?</div>
                  <div className="arrowGroup">
                      <div id="tr1arrow3" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow4" className="arrowSpin2">&nbsp;</div>
                  </div>
                  <div id="sfTR3" className="sfAccordionBody">
                    While you don't need an audience or substantial following — it does help generate even more traffic and results but is certainly not a requirement. 
                  </div>
                </div>

                <div className="sfAccordionRow" onClick={()=> this.toggleRow1('sfTR4')}>
                  <div className="sfAccordionHeading noselect" >How quickly will I see results?</div>
                  <div className="arrowGroup">
                      <div id="tr1arrow3" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow4" className="arrowSpin2">&nbsp;</div>
                  </div>
                  <div id="sfTR4" className="sfAccordionBody">
                    Results will vary depending on how quickly you can implement everything and how good your content is. When I use this system for clients, I can get them results in 24 hours.  <br /><br /> In one module, you'll see me walk through a complete demo of everything I setup. 
                  </div>
                </div>

                <div className="sfAccordionRow" onClick={()=> this.toggleRow1('sfTR6')}>
                  <div className="sfAccordionHeading noselect">what happens after I purchase?</div>
                  <div className="arrowGroup">
                      <div id="tr1arrow3" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow4" className="arrowSpin2">&nbsp;</div>
                  </div>
                  <div id="sfTR6" className="sfAccordionBody">
                    Once you click "Get Automated Creator — you'll be taken to the course checkout page. After you pay, you'll be redirected to thinkific to creator your account and access the course. I'll also send you an email a few minutes after that will contain your welcome kit. If you don't get that email, check your spam folder first, then you can reach out to me on Instagram or via email steve@stevenmellor.com
                  </div>
                </div>

                <div className="sfAccordionRow" onClick={()=> this.toggleRow1('sfTR12')}>
                  <div className="sfAccordionHeading noselect">Is this course included in your Membership?</div>
                  <div className="arrowGroup">
                      <div id="tr1arrow3" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow4" className="arrowSpin2">&nbsp;</div>
                  </div>
                  <div id="sfTR12" className="sfAccordionBody">
                   No. This is not included in the Zero to 100K membership. They're two separate products. 
                  </div>
                </div>

                <div className="sfAccordionRow" onClick={()=> this.toggleRow1('sfTR2')}>
                  <div className="sfAccordionHeading noselect">Can I get a refund?
                    <div className="arrowGroup">
                      <div id="tr1arrow" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow2" className="arrowSpin2">&nbsp;</div>
                    </div>
                  </div>
                  <div id="sfTR2" className="sfAccordionBody">
                  Yes! I want your purchase to be 100% risk free. Simply request a refund within 7 days of purchase (granted you didn't install any of the automation funnels). Reach out on Instagram or by email at steve@stevenmellor.com.
                  </div>
                </div>

                <div className="sfAccordionRow" onClick={()=> this.toggleRow1('sfTR5')}>
                  <div className="sfAccordionHeading noselect">Do I need additional software?</div>
                  <div className="arrowGroup">
                      <div id="tr1arrow3" className="arrowSpin">&nbsp;</div>
                      <div id="tr1arrow4" className="arrowSpin2">&nbsp;</div>
                  </div>
                  <div id="sfTR5" className="sfAccordionBody">
                    Here's a tech friendly stack that I recommend for best results:<br /><br />
                    ✔ Instagram Connected to Facebook<br />
                    ✔ ManyChat <br />
                    ✔ Email Client (I use Flodesk)<br />
                    ✔ Zapier or Make.com for Integration<br /><br />
                    You don't need everything setup at once, you can always add these as you progress.
                  </div>
                </div>
                
              </div>


            </div>

        </div>


        <div className="fullColumn dark squiggle">
          <img src={squiggleLine} width={200}/>
        </div>

        <div className="fullColumn dark">
            <div className="marginColumn maxW700 rowBasedLeft  ">
            <div className="sfH2 centerText  padBottom50">The Automated Creator Life</div>
            <div className="sfBody padBottom50">
            What’s good!? Steve Mellor here
            <br /><br />
            I’ve been growing and monetizing Instagrams since 2019 — I had a ton of fun and helped creators add millions of followers in the process. 
            <br /><br />
            Things were good — until they weren’t. 
            <br /><br />
            Burnout hit <i>annnnnddd</i> I needed to do something about it. This is where the idea of ‘Automated Creator’ came from. 
            <br /><br />
            Being able to automate the messy parts of a creator business without the need to hire assistants, add on employees, and still continue to grow — has been a career saver. 
            <br /><br />
            The ability to have my creator business working for me while I’m off creating content (or even sleeping) is what we all strive for as creators, <i>amiright?</i>
            <br /><br />
           <strong> Here are a few reasons why I built this system: </strong><br />
            1. To save loads of time on managing my DMs, comments, and growing my Instagram<br />
            2. To increase my views, reach, and engagement across the board <br />
            3. To generate more leads and sales — on autopilot (yes, it's cliche, but I guess you could say it's passive).
            <br /><br />
           Feel free to <mark><a href="#demoSect2">watch the demo below to get a feel for how amazing this system is!</a></mark>
            <br /><br />
            p.s. these didn’t just work on my account, they’ve captured over 30,000 emails, generated over 427,000 Story Views, and started 50K conversations in the DMs for clients — now I’m bringing the same systems to you.
          
            </div>

          </div>
        </div>

        <div className="fullColumn dark squiggle">
          <img src={squiggleLine} width={200}/>
        </div>

        <div  id="featSect" className="fullColumn dark">
            <div className="marginColumn  rowBased padTop50 padBottom50">
            <div className="sfH2 centerText padBottom50">More wins 🎉</div>
            
            <div className="proof3"><img src={mgTest} /></div>
            </div>
        </div>

        <div className="fullColumn dark">
            <div className="marginColumn rowBased  padBottom150">
            
              <div className="sfH5 centerText gold">Last call!</div>
              <div className="sfH2 justifyCenter ">You're 24hrs away from getting massive results </div>
              <div className="sfBody justifyCenter padBottom50">Don't waste time fidgeting with automations — install ours and you'll be up and running in 24 hours.</div>
              <a href="#pricingSect"><div className="sfButton" onClick={() => this.logConv('buy_24hours')}><img className="badgeLogo" src={boltIcon} />Unlock Instant Access</div></a>
            </div>
        </div>


        <footer className="footer footer-center p-10 bg-base-200 text-base-content rounded dark">
          <nav className="grid grid-flow-col gap-4">
            {/* <a className="link link-hover">About us</a>
            <a className="link link-hover">Contact</a>
            <a className="link link-hover">Jobs</a>
            <a className="link link-hover">Press kit</a> */}
          </nav> 
          <nav>
            <div className="grid grid-flow-col gap-4">
              <a href="https://instagram.com/thestevenmellor" target="_new">Instagram</a>
              <a href="https://tiktok.com/@thestevenmellor" target="_new">TikTok</a>
              <a href="https://twitter.com/thestevenmellor" target="_new">X/Twitter</a>
              <a href="https://linkedIn.com/in/thestevenmellor" target="_new">LinkedIn</a>
              <a href="https://youtube.com/@thestevenmellor" target="_new">YouTube</a>
            </div>
          </nav> 
          <aside>
            <p className="copyright">Copyright © 2023 - All right reserved by Steven Mellor / @thestevenmellor and Ampliphy, LLC.</p>
          </aside>
        </footer>
        </div>
      </div>
  );
  }
}





