import '../App.css';
import React, { Component, useEffect } from "react";

import Plausible from 'plausible-tracker';


import kcreator from '../assets/z100K.png';

const plausible = Plausible({
  domain: 'thestevenmellor.com',
  trackLocalhost: true
})

const { trackPageview } = Plausible()
const { trackEvent } = Plausible()




export default class WelcomeZero extends Component {


  constructor(props) {
    super(props);

    this.state = {
      value: '',
 
    }
      
  }


  componentDidMount(){
    trackPageview('pageview');
  }

  regularBtn(dir, tracker){

    console.log('I clicked: ', dir + ' ' + tracker);
    trackEvent(tracker);
    window.open(dir, "_blank");
  }



  render() {
    return (
        <div className="mainCont lander">
          <div className='marginTop'>&nbsp;</div>
            <div className='buttonTitle '></div>
            
            <div className='featureCard'>
                  {/* <div className="floatingPrice">Coming Soon</div> */}
                  <img className='eventCard' src={kcreator} alt="featured" width="100"/>
                  {/* <div className="metaCard">
                      <div className="metaItem">60 Minutes</div>
                      <div className="metaItem">4 Templates</div>
                      <div className="metaItem">Notion Guide</div>
                  </div> */}
                  <div className='buttonTitle'>Welcome, my friend!</div>
                  <div className='buttonDesc  marTop30'>Stoked you're here and excited to get started!
                  <br /><br /><i>...But first a couple of things just happened.</i>
                  <br /><br />1. You just got an email from me inviting you to the Community (hosted on Discord).
                  <br /><br />2. While you're waiting for that email, tap here <a href="https://discord.gg/pCVms48mpX" target="_blank"><strong><mark>jump in the Discord community&nbsp;</mark></strong></a>
                  <br /><br />3. If you don't get an email, <a href="https://instagram.com/thestevenmellor"><strong><mark>&nbsp;&nbsp;shoot me a DM&nbsp;&nbsp;</mark></strong></a> and I'll get it sorted out
                  <br /><br />I'll see you on the inside — 
                  <br /><br />/SM 👊
                  <br /><br />
                  </div>
                  
                     
                  {/* <div className="featureBtn" onClick={this.handleSubmit}>'Join The Waitlist'</div> */}
                  
                  
            </div>
        </div>
    );
  }
}





