import '../App.css';
import React, { Component, useEffect } from "react";

import boltIcon from '../assets/boltIcon.svg';
import bigLogo from '../assets/ac-logo-type.png'
import boxFrontSale from '../assets/box-sale-front.png';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

var Buffer = require('buffer/').Buffer 



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDEs9eZ7uZr15k4snOwOBwemTnflooj8-Y",
  authDomain: "mellorlink.firebaseapp.com",
  projectId: "mellorlink",
  storageBucket: "mellorlink.appspot.com",
  messagingSenderId: "603346648587",
  appId: "1:603346648587:web:5fbd4a6f7afeae7e28110e",
  measurementId: "G-31CBLFG330"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const fdKey = process.env.REACT_APP_FD_API_KEY






// Initialize Firebase
// const app = initializeApp(firebaseConfig);

export default class WelcomeAc extends Component {


  constructor(props) {
    super(props);

    this.state = {
      value: '',
      email: '',
      error: '',
      isValidEmail: true,
      submitted: false,
      isLoading: false,
      submittedSuccessfully: false, // Add this state variable
    }
      
  }


  componentDidMount(){
    logEvent(analytics, 'ac_purchase');
    // console.log('ac_purchase');
  }


  featBtn(dir, tracker){

    // console.log('I clicked: ', dir + ' ' + tracker);
    this.logConv(tracker);

    window.open(dir, "_blank");
  }

  regularBtn(dir, tracker){

    // console.log('I clicked: ', dir + ' ' + tracker);
    this.logConv(tracker);
    window.open(dir, "_blank");
  }


  logConv(pos){
    logEvent(analytics, pos);
    // console.log('fired event: ', pos);
    return 
  }


  
  render() {
    

    return (
        <div className="mainCont">
          <div className="bigLogo">
            <img src={bigLogo} className="bigLogoSize" />
            {/* <img src={boxFrontSale} className="bigLogoSize"/> */}
          </div>
          <div className="superText">YOU'RE IN!</div>
          <div className="marginCol brightText centerText">Welcome, my friend — Stoked to have you here! <br />

  now, let’s get you setup.</div>

          {/* <div className='featureCard' onClick={()=>this.featBtn('https://thestevenmellor.myflodesk.com/mt6bjnt6j1', '100K Creator')}> */}
         
          
          
          <a href="https://thestevenmellor.thinkific.com/courses/take/automated-creator/texts/51043863-start-here-resources" onClick={() => this.logConv('welcome_templates')} className="padTop50"  target="_new">
              <div className='buttonRow2'>
                  <img src={boltIcon} alt="" className="buttonRowIconSm"/>
                  <div className='buttonTitle2'>Download the templates<span className="halo">&nbsp;-&gt;</span></div>
              </div>
          </a>
          {/* <a href="https://discord.gg/Ys9Jys9cgd" onClick={() => this.logConv('welcome_discord')}  target="_new">
              <div className='buttonRow2'>
              <img src={boltIcon} alt="" className="buttonRowIconSm"/><img src={boltIcon} alt="" className="buttonRowIconSm"/>
                  <div className='buttonTitle2'>Join the private Discord<span className="halo">&nbsp;-&gt;</span></div>
              </div>
          </a> */}
          <a href="https://thestevenmellor.thinkific.com/courses/take/automated-creator/multimedia/51043427-welcome-to-automated-creator" onClick={() => this.logConv('welcome_lessonOne')} target="_new">
              <div className='buttonRow2'>
                  <img src={boltIcon} alt="" className="buttonRowIconSm"/>
                  <img src={boltIcon} alt="" className="buttonRowIconSm"/>
                  <img src={boltIcon} alt="" className="buttonRowIconSm"/>
                  <div className='buttonTitle2'>Watch Lesson No. 1<span className="halo">&nbsp;-&gt;</span></div>
              </div>
          </a>

          <div className="marginCol brightText centerText padTop50 padBottom10">Last thing — keep an eye on your email. I'll be sending over a few onboarding emails to make sure you get a smooth start.</div>
          <div className="marginCol brightText centerText padTop50 footerText">Any issues, my DMs are open</div>
          
          
   
          


          

          

        
        
        </div>
    );
    }
}





