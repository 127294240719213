import '../App.css';
import React, { Component } from "react";
import { useNavigate } from 'react-router-dom';

class Youtube extends Component {
  componentDidMount() {
    const youtubeAppUrl = 'vnd.youtube://www.youtube.com/@thestevenmellor';
    const youtubeWebUrl = 'https://youtube.com/@thestevenmellor';

    // Attempt to redirect to the YouTube app
    window.location.href = youtubeAppUrl;

    // Fallback to the web URL after a short delay
    setTimeout(() => {
      window.location.href = youtubeWebUrl;
    }, 500);

    // Redirect to another route to prevent staying on the /youtube path
    this.props.navigate('/');
  }

  render() {
    return (
      <div className="ytRedirect">
        <p>Redirecting to YouTube...</p>
      </div>
    );
  }
}

function withNavigate(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  }
}

export default withNavigate(Youtube);
