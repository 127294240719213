import './App.css';
import React, { Component, useEffect } from "react";
import {Helmet} from "react-helmet";
import { BrowserRouter as Router, Route, Routes, Redirect } from 'react-router-dom'



//import pages
import Home from "./pages/Home";
import Caption from "./pages/Caption";
import Zero from "./pages/Zerolp";
import WelcomeZero from "./pages/WelcomeZero";
import WelcomeAc from "./pages/Welcome-ac";
import Webinar from "./pages/Webinar";
import Youtube from './pages/Youtube';

function App() {

  return (

    <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/commentig/" element={<Caption/>}/>
          <Route exact path="/youtube" element={<Youtube/>}/>
          <Route exact path="/stories-webinar" element={<Webinar/>}/>
          <Route exact path="/automatedcreator" element={<Zero/>}/>
          <Route exact path="/stories-training" element={<WelcomeZero/>}/>
          <Route exact path="/welcome-ac" element={<WelcomeAc/>}/>
          <Route path='*' exact={true} element={<Home/>} />

        </Routes>
    </Router>
  );
}

export default App;












